import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'src/app/services/api.base';
import { BaseCountryCurrency } from '../models/base-currency-country.model';
import { Customer } from '../models/customer.model';
import { CostArea } from '../models/cost-area';
import { Executor, ExecutorCostArea } from '../models/executor.model';
import { Position } from '../models/position.model';
import { ProjectStatus } from '../models/project-statuses.model';
import { QuoteRate } from '../models/quote-rate.model';
import { SettingsTermsAndConditions } from '../models/quote-terms-and-conditions.model';
import { SystemRoles } from '../models/roles.model';
import { ExpenseArea } from '../models/expense-area.model';
import { SystemNotifications } from '../models/system-notifications.model';
import { SystemNotificationCRUD } from '../models/system-notification-crud.model';
import { Project } from '../../projects/models/project.model';
import { Vendor, VendorsResponse } from '../models/vendors';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService extends ApiService {
  constructor(http: HttpClient) {
    super(http);
  }

  public getCountries(): Observable<BaseCountryCurrency[]> {
    const url: string = `${this.endpoint}system/countries`;

    return this.http.get<BaseCountryCurrency[]>(url);
  }

  public getTermsAndConditions(): Observable<SettingsTermsAndConditions> {
    const url: string = `${this.endpoint}system/quotes/terms-and-conditions`;

    return this.http.get<SettingsTermsAndConditions>(url);
  }

  public createCountry(
    code: string,
    name: string
  ): Observable<BaseCountryCurrency> {
    const url: string = `${this.endpoint}system/countries`;

    return this.http.post<BaseCountryCurrency>(url, { code, name });
  }

  public editTermsAndConditions(
    termsAndConditions: string
  ): Observable<SettingsTermsAndConditions> {
    const url: string = `${this.endpoint}system/quotes/terms-and-conditions`;

    return this.http.put<SettingsTermsAndConditions>(url, {
      termsAndConditions,
    });
  }

  public editCountry(
    code: string,
    name: string,
    id: string
  ): Observable<BaseCountryCurrency> {
    const url: string = `${this.endpoint}system/countries/${id}`;

    return this.http.put<BaseCountryCurrency>(url, { code, name });
  }

  public deleteCountry(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/countries/${id}`;

    return this.http.delete<object>(url);
  }

  public getCurrencies(): Observable<BaseCountryCurrency[]> {
    const url: string = `${this.endpoint}system/currencies`;

    return this.http.get<BaseCountryCurrency[]>(url);
  }

  public createCurrency(
    code: string,
    name: string
  ): Observable<BaseCountryCurrency> {
    const url: string = `${this.endpoint}system/currencies`;

    return this.http.post<BaseCountryCurrency>(url, { code, name });
  }

  public editCurrency(
    code: string,
    name: string,
    id: string
  ): Observable<BaseCountryCurrency> {
    const url: string = `${this.endpoint}system/currencies/${id}`;

    return this.http.put<BaseCountryCurrency>(url, { code, name });
  }

  public deleteCurrency(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/currencies/${id}`;

    return this.http.delete<object>(url);
  }

  public getPositions(): Observable<Position[]> {
    const url: string = `${this.endpoint}system/positions`;

    return this.http.get<Position[]>(url);
  }

  public createPosition(position: string): Observable<Position> {
    const url: string = `${this.endpoint}system/positions`;

    return this.http.post<Position>(url, { position });
  }

  public editPosition(
    position: string,
    positionId: string
  ): Observable<Position> {
    const url: string = `${this.endpoint}system/positions/${positionId}`;

    return this.http.put<Position>(url, { position });
  }

  public deletePosition(positionId: string): Observable<object> {
    const url: string = `${this.endpoint}system/positions/${positionId}`;

    return this.http.delete<object>(url);
  }

  public getSystemRoles(): Observable<SystemRoles[]> {
    const url: string = `${this.endpoint}system/roles`;

    return this.http.get<SystemRoles[]>(url);
  }

  public getArchivedSystemRoles(): Observable<SystemRoles[]> {
    const url: string = `${this.endpoint}system/roles/archived`;

    return this.http.get<SystemRoles[]>(url);
  }

  public createSystemRole(
    role: string,
    modules: string[]
  ): Observable<SystemRoles> {
    const url: string = `${this.endpoint}system/roles`;

    return this.http.post<SystemRoles>(url, { modules, role });
  }

  public editSystemRole(
    role: string,
    modules: string[],
    id: string
  ): Observable<SystemRoles> {
    const url: string = `${this.endpoint}system/roles/${id}`;

    return this.http.put<SystemRoles>(url, { modules, role });
  }

  public deleteSystemRole(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/roles/${id}`;

    return this.http.delete<object>(url);
  }

  public archiveSystemRole(id: string): Observable<SystemRoles> {
    const url: string = `${this.endpoint}system/roles/${id}/archive`;

    return this.http.put<SystemRoles>(url, null);
  }

  public restoreSystemRole(id: string): Observable<SystemRoles> {
    const url: string = `${this.endpoint}system/roles/${id}/restore`;

    return this.http.put<SystemRoles>(url, null);
  }

  public getCustomers(): Observable<Customer[]> {
    const url: string = `${this.endpoint}system/customers`;

    return this.http.get<Customer[]>(url);
  }

  public editCustomerFinanceEmail(
    customerId: string,
    financeEmail: string
  ): Observable<Customer> {
    const url: string = `${this.endpoint}system/customers/${customerId}/finance-email`;

    return this.http.patch<Customer>(url, { financeEmail });
  }

  public getArchivedCustomers(): Observable<Customer[]> {
    const url: string = `${this.endpoint}system/customers/archived`;

    return this.http.get<Customer[]>(url);
  }

  public getCustomer(id: string): Observable<Customer> {
    const url: string = `${this.endpoint}system/customers/${id}`;

    return this.http.get<Customer>(url);
  }

  public createCustomer(customer: Customer): Observable<Customer> {
    const url: string = `${this.endpoint}system/customers`;

    return this.http.post<Customer>(url, customer);
  }

  public editCustomer(customer: Customer, id: string): Observable<Customer> {
    const url: string = `${this.endpoint}system/customers/${id}`;

    return this.http.put<Customer>(url, customer);
  }

  public deleteCustomer(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/customers/${id}`;

    return this.http.delete<object>(url);
  }

  public archiveCustomer(id: string): Observable<Customer> {
    const url: string = `${this.endpoint}system/customers/${id}/archive`;

    return this.http.put<Customer>(url, null);
  }

  public restoreCustomer(id: string): Observable<Customer> {
    const url: string = `${this.endpoint}system/customers/${id}/restore`;

    return this.http.put<Customer>(url, null);
  }

  public getCostAreas(): Observable<CostArea[]> {
    const url: string = `${this.endpoint}system/cost-areas`;

    return this.http.get<CostArea[]>(url);
  }

  public getArchivedCostAreas(): Observable<CostArea[]> {
    const url: string = `${this.endpoint}system/cost-areas/archived`;

    return this.http.get<CostArea[]>(url);
  }

  public createCostArea(
    costArea: string,
    isMain: boolean
  ): Observable<CostArea> {
    const url: string = `${this.endpoint}system/cost-areas`;

    return this.http.post<CostArea>(url, { costArea, isMain });
  }

  public editCostArea(
    costArea: string,
    isMain: boolean,
    id: string
  ): Observable<CostArea> {
    const url: string = `${this.endpoint}system/cost-areas/${id}`;

    return this.http.put<CostArea>(url, { costArea, isMain });
  }

  public deleteCostArea(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/cost-areas/${id}`;

    return this.http.delete<object>(url);
  }

  public archiveCostArea(id: string): Observable<CostArea> {
    const url: string = `${this.endpoint}system/cost-areas/${id}/archive`;

    return this.http.post<CostArea>(url, null);
  }

  public restoreCostArea(id: string): Observable<CostArea> {
    const url: string = `${this.endpoint}system/cost-areas/${id}/restore`;

    return this.http.post<CostArea>(url, null);
  }

  public getProjectStatuses(): Observable<ProjectStatus[]> {
    const url: string = `${this.endpoint}system/projects/status`;

    return this.http.get<ProjectStatus[]>(url);
  }

  public getArchivedProjectStatuses(): Observable<ProjectStatus[]> {
    const url: string = `${this.endpoint}system/projects/status/archived`;

    return this.http.get<ProjectStatus[]>(url);
  }

  public createProjectStatus(status: string): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/projects/status`;

    return this.http.post<ProjectStatus>(url, { status });
  }

  public editProjectStatus(
    status: string,
    id: string
  ): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/projects/status/${id}`;

    return this.http.put<ProjectStatus>(url, { status });
  }

  public deleteProjectStatus(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/projects/status/${id}`;

    return this.http.delete<object>(url);
  }

  public archiveProjectStatus(id: string): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/projects/status/${id}/archive`;

    return this.http.put<ProjectStatus>(url, null);
  }

  public restoreProjectStatus(id: string): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/projects/status/${id}/restore`;

    return this.http.put<ProjectStatus>(url, null);
  }

  public getExecutor(executorId: string): Observable<Executor> {
    const url: string = `${this.endpoint}system/executors/${executorId}`;

    return this.http.get<Executor>(url);
  }

  public getExecutors(): Observable<Executor[]> {
    const url: string = `${this.endpoint}system/executors`;

    return this.http.get<Executor[]>(url);
  }

  public getPublicExecutors(keyWord: string): Observable<Executor[]> {
    const url: string = `${this.endpoint}system/executors/public?keyWord=${keyWord}`;

    return this.http.get<Executor[]>(url);
  }

  public getArchivedExecutors(): Observable<Executor[]> {
    const url: string = `${this.endpoint}system/executors/archived`;

    return this.http.get<Executor[]>(url);
  }

  public getPublicProjects(): Observable<Project[]> {
    const url: string = `${this.endpoint}system/projects/public`;

    return this.http.get<Project[]>(url);
  }

  public getProjectExecutors(projectId: string): Observable<Executor[]> {
    const url: string = `${this.endpoint}system/projects/${projectId}/executors`;

    return this.http.get<Executor[]>(url);
  }

  public getProjectPhaseExecutors(
    projectId: string,
    phaseId: string
  ): Observable<Executor[]> {
    const url: string = `${this.endpoint}system/projects/${projectId}/phases/${phaseId}/executors`;

    return this.http.get<Executor[]>(url);
  }

  public createExecutor(
    name: string,
    defaultCustomerRate: number,
    costAreaIds: string[]
  ): Observable<Executor> {
    const url: string = `${this.endpoint}system/executors`;

    return this.http.post<Executor>(url, {
      name,
      defaultCustomerRate,
      costAreaIds,
    });
  }

  public editExecutor(
    name: string,
    defaultCustomerRate: number,
    costAreaIds: string[],
    id: string
  ): Observable<Executor> {
    const url: string = `${this.endpoint}system/executors/${id}`;

    return this.http.put<Executor>(url, {
      name,
      defaultCustomerRate,
      costAreaIds,
    });
  }

  public archiveExecutor(id: string): Observable<null> {
    const url: string = `${this.endpoint}system/executors/${id}/archive`;

    return this.http.post<null>(url, null);
  }

  public restoreExecutor(id: string): Observable<null> {
    const url: string = `${this.endpoint}system/executors/${id}/restore`;

    return this.http.post<null>(url, null);
  }

  public deleteExecutor(id: string): Observable<null> {
    const url: string = `${this.endpoint}system/executors/${id}`;

    return this.http.delete<null>(url);
  }

  public getQuoteStatuses(): Observable<ProjectStatus[]> {
    const url: string = `${this.endpoint}system/quotes/status`;

    return this.http.get<ProjectStatus[]>(url);
  }

  public getArchivedQuoteStatuses(): Observable<ProjectStatus[]> {
    const url: string = `${this.endpoint}system/quotes/status/archived`;

    return this.http.get<ProjectStatus[]>(url);
  }

  public createQuoteStatus(status: string): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/quotes/status`;

    return this.http.post<ProjectStatus>(url, { status });
  }

  public editQuoteStatus(
    status: string,
    id: string
  ): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/quotes/status/${id}`;

    return this.http.put<ProjectStatus>(url, { status });
  }

  public deleteQuoteStatus(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/quotes/status/${id}`;

    return this.http.delete<object>(url);
  }

  public restoreQuoteStatus(id: string): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/quotes/status/${id}/restore`;

    return this.http.put<ProjectStatus>(url, null);
  }

  public archiveQuoteStatus(id: string): Observable<ProjectStatus> {
    const url: string = `${this.endpoint}system/quotes/status/${id}/archive`;

    return this.http.put<ProjectStatus>(url, null);
  }

  public getQuoteRates(): Observable<QuoteRate[]> {
    const url: string = `${this.endpoint}system/settings/quotes/rates`;

    return this.http.get<QuoteRate[]>(url);
  }

  public createQuoteRate(data: {
    rate: number;
    comment: string;
  }): Observable<QuoteRate> {
    const url: string = `${this.endpoint}system/settings/quotes/rates`;

    return this.http.post<QuoteRate>(url, data);
  }

  public editQuoteRate(
    data: { rate: number; comment: string },
    id: string
  ): Observable<QuoteRate> {
    const url: string = `${this.endpoint}system/settings/quotes/rates/${id}`;

    return this.http.put<QuoteRate>(url, data);
  }

  public deleteQuoteRate(id: string): Observable<object> {
    const url: string = `${this.endpoint}system/settings/quotes/rates/${id}`;

    return this.http.delete<object>(url);
  }

  public getExpenseAreas(): Observable<ExpenseArea[]> {
    const url: string = `${this.endpoint}system/expense-areas`;

    return this.http.get<ExpenseArea[]>(url);
  }

  public getArchivedExpenseAreas(): Observable<ExpenseArea[]> {
    const url: string = `${this.endpoint}system/expense-areas/archived`;

    return this.http.get<ExpenseArea[]>(url);
  }

  public createExpenseArea(
    expenseArea: string,
    markup: number
  ): Observable<ExpenseArea> {
    const url: string = `${this.endpoint}system/expense-areas`;

    return this.http.post<ExpenseArea>(url, { expenseArea, markup });
  }

  public editExpenseArea(
    expenseArea: string,
    markup: number,
    id: string
  ): Observable<ExpenseArea> {
    const url: string = `${this.endpoint}system/expense-areas/${id}`;

    return this.http.put<ExpenseArea>(url, { expenseArea, markup });
  }

  public deleteExpenseArea(id: string): Observable<null> {
    const url: string = `${this.endpoint}system/expense-areas/${id}`;

    return this.http.delete<null>(url);
  }

  public archiveExpenseArea(id: string): Observable<ExpenseArea> {
    const url: string = `${this.endpoint}system/expense-areas/${id}/archive`;

    return this.http.put<ExpenseArea>(url, null);
  }

  public restoreExpenseArea(id: string): Observable<ExpenseArea> {
    const url: string = `${this.endpoint}system/expense-areas/${id}/restore`;

    return this.http.put<ExpenseArea>(url, null);
  }

  public getSystemNotifications(): Observable<SystemNotifications[]> {
    const url: string = `${this.endpoint}system-notifications`;

    return this.http.get<SystemNotifications[]>(url);
  }

  public createSystemNotifications(
    notification: SystemNotifications
  ): Observable<SystemNotifications> {
    const url: string = `${this.endpoint}system-notifications`;

    return this.http.post<SystemNotifications>(url, notification);
  }

  public editSystemNotifications(
    notification: SystemNotificationCRUD,
    id: string
  ): Observable<SystemNotifications> {
    const url: string = `${this.endpoint}system-notifications/${id}`;

    return this.http.put<SystemNotifications>(url, notification);
  }

  public deleteSystemNotifications(id: string): Observable<null> {
    const url: string = `${this.endpoint}system-notifications/${id}`;

    return this.http.delete<null>(url);
  }

  public getSystemVersion(): Observable<{ version: string }> {
    const url: string = `${this.endpoint}system/variables/version`;

    return this.http.post<{ version: string }>(url, null);
  }

  public getVendors(): Observable<VendorsResponse> {
    const url: string = `${this.endpoint}system/vendors/search`;

    return this.http.post<VendorsResponse>(url, { limit: null, offset: 0 });
  }

  public getArchivedVendors(): Observable<VendorsResponse> {
    const url: string = `${this.endpoint}system/vendors/search-archived`;

    return this.http.post<VendorsResponse>(url, { limit: null, offset: 0 });
  }

  public createVendor(name: string): Observable<Vendor> {
    const url: string = `${this.endpoint}system/vendors`;

    return this.http.post<Vendor>(url, { name });
  }

  public editVendor(id: string, name: string): Observable<Vendor> {
    const url: string = `${this.endpoint}system/vendors/${id}`;

    return this.http.put<Vendor>(url, { name });
  }

  public archiveVendor(id: string): Observable<Vendor> {
    const url: string = `${this.endpoint}system/vendors/${id}/archive`;

    return this.http.patch<Vendor>(url, null);
  }

  public restoreVendor(id: string): Observable<Vendor> {
    const url: string = `${this.endpoint}system/vendors/${id}/restore`;

    return this.http.patch<Vendor>(url, null);
  }
}
