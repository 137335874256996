import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SettingsApiService } from './modules/settings/services/settings-api.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit, OnDestroy {
  frontEndVersion = '2025.03.03.05';
  backEndVersion!: string;

  private readonly destroy$ = new Subject();

  constructor(private settingsService: SettingsApiService) {}

  ngOnInit(): void {
    this.prepareToShowBackEndVersion();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private prepareToShowBackEndVersion(): void {
    this.settingsService
      .getSystemVersion()
      .pipe(takeUntil(this.destroy$))
      .subscribe((version) => {
        this.backEndVersion = version.version;
      });
  }
}
